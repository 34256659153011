export const APP_SCREEN_NAME = {
  STACK_SCREEN: 'StackScreen',
  OUTLINK_SCREEN: 'OutLinkScreen',
  LESSON_SCREEN: 'Lesson',
  LESSON_ENTER: 'Enterance',
  BSFHome: 'BSFHome',
  PAYMENT_SCREEN: 'PaymentWebView',
  PortalHome: 'PortalHome',
  CONVERSATION_SCREEN: 'ConversationScreen',
  WELCOME_SCREEN: 'WelcomeScreen',
  IAP_SCREEN: 'IAPScreen',
}

export const STACK_ACTIVITY_NAME = {
  CREDIT_DETAIL_ACTIVITY: 'MyCreditDetailActivity',
  CONVERSATION_CREDIT_DETAIL_ACTIVITY: 'ConversationCreditDetailActivity',
  TUTOR_SEARCH_ACTIVITY: 'TutorSearchActivity',
  BLOG_DETAIL_ACTIVITY: 'BlogDetailActivity',
  BLOG_MAIN_ACTIVITY: 'BlogMainAcitivity',
  EVENT_LIST_ACTIVITY: 'EventListActivity',
  EVENT_TEST_ACTIVITY: 'EventTestActivity',

  EVENT_DETAIL_ACTIVITY: 'EventDetailActivity',

  /* CHALLENGE */
  CHALLENGE_MAIN_ACTIVITY: 'ChallengeMainActivity',
  CHALLENGE_DETAIL_ACTIVITY: 'ChallengeDetailActivity',
  OPTION_SELECT_ACTIVITY: 'OptionSelectActivity',
  MY_BADGES_ACTIVITY: 'MyBadgesActivity',
  ENDED_CHALLENGE_ACTIVITY: 'EndedChallengeActivity',
  CHALLENGE_LIST_ACTIVITY: 'ChallengeListActivity',
  CHALLENGE_PRICING_MAIN_ACTIVITY: 'ChallengePricingMainActivity',
  ACHIEVEMENTS_ACTIVITY: 'AchievementsActivity',

  LESSON_MATERIAL_DETAIL_ACTIVITY: 'LessonMaterialDetailActivity',
  HOME_MATERIAL_DETAIL_ACTIVITY: 'HomeMaterialDetailActivity',
  STUDY_DETAIL_ACTIVITY: 'StudyDailyActivity',
  BITE_SIZED_FEEDBACK_LANDING_ACTIVITY: 'BiteSizedFeedbackLandingActivity',
  POINT_DETAIL_ACTIVITY: 'PointActivity',
  POINT_PURCHASE_ACTIVITY: 'PointPurchaseActivity',
  COUPONBOX_DETAIL_ACTIVITY: 'CouponBoxActivity',
  INVITE_DETAIL_ACTIVITY: 'InviteActivity',
  MATERIAL_LIST_ACTIVITY: 'MaterialListActivity',
  MATERIAL_DETAIL_ACTIVITY: 'MaterialsDetailActivity',
  MATERIAL_CATEGORY_ACTIVITY: 'MaterialsCategoryActivity',
  PRICING_MAIN_ACTIVITY: 'PricingMainActivity',
  PRICING_DETAIL_ACTIVITY: 'PricingDetailActivity',
  HELP_CENTER_DETAIL_ACTIVITY: 'HelpCenterDetailActivity',
  READ_TOGETHER_DETAIL_ACTIVITY: 'ReadTogetherDetailActivity',
  READ_ALONE_DETAIL_ACTIVITY: 'ReadAloneDetailActivity',

  /* CONVERSATION AI */
  CONVERSATION_AI_LIST_ACTIVITY: 'ConversationListActivity',
  CONVERSATION_MATERIAL_DETAIL_ACTIVITY: 'ConversationMaterialDetailActivity',
}
