import { default as colors } from '../colors'
import { getEnv } from './envUtil'
import { COOKIE_DOMAIN, IS_LOCAL, IS_PRODUCTION } from './envVars'

// KEYS
export const KAKAO_KEY = '8ea5c27d56129fb5a4cff41d32b29aa8'
export const KAKAO_CLIENT_ID = 'fe413f9caef34b94d6280fc46e43600a'
export const PUSHER_APP_KEY = '6f4e2acaf256ab429686'

// WEBSPELLCHECKER KEY
export const SPELLCHECKER_KEY = getEnv('CONF_SPELLCHECK_ID', undefined)
// Production
export const NAVER_KEY = '0MXYp7ZydqKJLZjzJSvM'

// URLS
export const CDN_URL = 'https://d38emex6h5e12i.cloudfront.net'

export const CHAR_CIRCLE = '●'
export const SECTION_CLASS = 'py-6'

// 자동 매칭
export const AUTO_MATHCING_ID = 'auto-matching'
export const IMAGE_AUTO_MATCHING = '/assets/img/logo/logo1.png'

// 유저 역할
export const ROLE_STUDENT = 0
export const ROLE_TUTOR = 1
export const ROLE_ADMIN = 3

// 결제 타입
export const IAMPORT_NOAUTH = 0
export const IAMPORT_INTL = 1
// export const MANUAL_AUTH = 2
// export const ADMIN_GRANT = 3
export const IAMPORT_AUTH = 4
export const PAYPAL_EXPRESS = 5
export const STRIPE = 6
export const RECURRING = 7
// export const UNDECIDED = 8
export const TOSS_AUTH = 9
export const VBANK = 10

export const PAYMENT_TYPE = {
  TOSS_PAY: 'tosspay',
  NORMAL: 'card_normal',
  DIRECT: 'card_direct',
  KAKAO: 'kakaopay',
  SAMSUNG: 'samsung',
  PAYCO: 'payco',
  NAVERPAY: 'naverpay',
  TRANS: 'trans',
  VBANK: 'vbank',
  PAYPAL: 'paypal',
  STRIPE: 'stripe',
  INSTALLMENT: 'card_partial',
  SAMSUNG_CARD: 'samsung_card',
}

export const PAYMENT_DIRECT_MODULE_TYPE = {
  SAMSUNG_CARD: '365',
}

export const CURRENCY = {
  KRW: 'krw',
  USD: 'usd',
}

export const COUNTRY_ALPHA2_CODE = {
  KR: 'KR',
  US: 'US',
  JP: 'JP',
  VN: 'VN',
  CN: 'CN',
  TW: 'TW',
}

// Iamport 결제
export const IAMPORT_KEY = 'imp96397147'
// export const PAYMENT_REGULAR = 'regular'
// export const PAYMENT_INSTALLMENT = 'installment'
// export const PAYMENT_SPLIT = 'split'
// export const PAYMENT_PERIOD = 'period'

// Stripe 결제
export const STRIPE_PK = getEnv('CONF_STRIPE_PK', undefined)

// 친구 초대 상태
// export const REF_SIGN_UP = 0
// export const REF_TRIAL_COMPLETE = 1
// export const REF_PURCHASED = 2

// 긴급 편성/보충 수업 타입
export const UNASSIGNED = 0
// export const URGENT = 1

export const APPLY_LESSON_LIMIT_TIME = 48

export const DOLLARS_TO_KRW = 1300

// Hackle SDK Key
export const HACKLE_SDK_KEY = getEnv('CONF_HACKLE_SDK_KEY', undefined)

export const IS_PARENT = (currentUser) => currentUser?.session_role == TEENS_SESSION_ROLE.PARENT //틴즈 부모 로그인 여부

// 쿠키
export const COOKIE_OPT = { domain: COOKIE_DOMAIN, path: '/' }
export const COOKIE_OPT_FOR_ACCESS_TOKEN = { domain: COOKIE_DOMAIN, path: '/', secure: !IS_LOCAL }
export const COOKIE_LIST = {
  JWT_TOKEN: 'jwt_token',
  CAF_TOKEN: 'caf_token',
  LOGIN_AFTER_URL: 'login_after_url',
  IS_ADMIN_LOGIN: 'is_admin_login',
  LOGGED_BY_PARENT: 'logged_by_parent',
  SESSION_ROLE: 'session_role',
  REFERRAL_CODE: 'referralCode',
  ROOT_URL: 'root_url',
  FRIEND: 'friend',
  PHONE: 'phone',
  PHONE_INTL: 'phone_intl',
  EMAIL: 'email',
  UTM_AD_INFO: 'utm_ad_info',
  PROVIDER: 'provider',
  UID: 'uid',
  USER_IMAGE: 'userImage',
  TIMEZONE: 'timezone',
  USER_ID: 'user_id',
  NAME: 'name',
  IS_GTM_LOADED: 'is_gtm_loaded',
  PREV_TUTOR_PEAKTIME: 'prev_peak_time',
  ACCOUNT_TYPE: 'user_type',
  CHILD_ID: 'child_id',
  ACCOUNT_ID: 'account_id',
  LOCALE: 'locale',
  LESSON_REFERRER: 'lesson_referrer',
  PAID_WEBINAR: 'hide_paid_webinar',
  REFERRAL: 'tutor_referral',
  RECOMMEND_TUTOR_POPUP: 'recommend_tutor_popup',
  USER_PROFILE: 'user_profile',
  // TEENS_OPEN: 'teens_open',
  // IS_CLOSED_CAF_MODAL_IN_REVIEW: 'is_closed_caf_modal_in_review',
  // IS_CLOSED_CAF_HIGHLIGHT_TOOLTIP: 'is_closed_caf_highlight_tooltip',
}

export const LESSON_REFERRER_COOKIE_LIST = {
  PORTAL_HOME: 'portal/home',
  SCHEDULE_TIME_FIRST: 'lesson/schedule/time',
  SCHEDULE_TUTOR_FIRST: 'lesson/schedule/tutor',
  TUTOR_LIST_RECOMMEND: 'portal/tutors/featured',
  TUTOR_LIST_ALL: 'portal/tutors/all',
  TUTOR_LIST_FAVORITE: 'portal/tutors/favorite',
  TUTOR_LIST_SEARCH: 'portal/tutors/search',
}

// S3 버켓
export const RECORDING_VIDEO_ASIA_BUCKET = 'recording-video-asia'
export const RECORDING_AUDIO_ASIA_BUCKET = 'recording-audio'
export const CONVERSATION_AUDIO_BUCKET = 'conversation-audio-tts'

// 취소시 옵션
export const MATCHING_AFTER = {
  AUTOMATIC_NOT_TUTOR_SELECTED: 0,
  AUTOMATIC_MATCHING_ME: 1,
  SHOW_AVAILABLE_TIMES: 2,
  CANCEL_RESTORE_CREDIT: 3,
}

export const TRIAL_COUNT = 3

// 결제 유저 타입
// export const NOT_PAID_USER = 1
// export const PAID_USER = 2

// 튜터 피드백 Status
export const TUTOR_FEEDBACK = {
  NOT_STARTED: 0,
  UNMET_CONDITION: 1,
  SUBMITTED_AND_NOT_CONFIMRED: 2,
  COMPLETED: 3,
  WAIVER_REQUESTED: 4,
  REJECTED: 5,
}

// 튜터 상태
export const TUTOR_STATUS = {
  APPLICATION: 0,
  ACTIVE: 1,
  NEW_TUTOR: 2,
  DORMANT: 3,
  READY: 4,
  ONE_STRIKE: 5,
  TWO_STRIKE: 6,
  OUT_THREE_STRIKE: 7,
  OUT_TEN_STRIKE: 8,
  HOLD: 9,
  BLACKLIST: 10,
  NOT_AGREED_POLICY: 11,
  UNDER_REVIEW: 12,
}

export const TUTOR_SCHEDULE_OPEN_STATUS = {
  AVAILABLE: 1,
  FULL_BOOKED: 2,
  NOT_OPEN_YET: 3,
}

// 교정 신청 타입
export const REVISED_TEXT = {
  CORRECTION: 0,
  PARAPHRASE: 1,
}

// 분할 결제 타입
export const PARTIAL_STATUS = {
  BEFORE: 0,
  START: 1,
  FINISH: 2,
}

export const SIGN_UP_MODE = {
  TUTOR: 'tutor',
  TOPIC: 'topic',
  TRIAL: 'trial',
  EVENT: 'event',
  CORRECTION: 'correction',
  PURCHASE: 'purchase',
  MATERIAL_SIGN_UP: 'material',
  MATERIAL_AD_SIGN_UP: 'material_ad_sign_up',
  MATERIAL_AD_SIGN_IN: 'material_ad_sign_in',
  SIGN_IN: 'sign_in',
}

export const SESSION_ROLE = {
  TUTOR: 'tutor',
  STUDENT: 'student',
  ADMIN: 'admin',
  CHILD: 'child',
  PARENT: 'parent',
}

export const TEENS_SESSION_ROLE = {
  TUTOR: 'tutor',
  STUDENT: 'student',
  ADMIN: 'admin',
  CHILD: 'child',
  PARENT: 'parent',
}

export const TEENS_ACCOUNT_TYPE = {
  TUTOR: 'Tutor',
  PLUS: 'Plus',
  PARENT: 'Parent',
  CHILD: 'Child',
  NEW: 'New',
  PLUS_PARENT: 'Plus_Parent',
  STUDENT: 'Student',
}
export const PAYLOAD_SESSION_ROLE = {
  STUDENT: 'student',
  PARENT: 'parent',
}
export const ONBOARDING_STAGE = {
  PARENT: {
    SIGNUP_FINISHED: 1,
    CHILD_ACCOUNT_CREATED: 2,
  },
  CHILD: {
    ONBOARDING_NOT_FINISHED: 0,
    ONBOARDING_FINISHED: 1,
  },
}
export const ROLE = {
  TUTOR: 1,
  STUDENT: 0,
  ADMIN: 3,
}

export const WEBINAR_CATEGORY = {
  GRAMMAR: 1,
  RINGLE_CLASS: 2,
  TREND: 3,
  RADIO: 4,
  BUSINESS: 5,
}

export const CONFIRM_STATUS = {
  CONFIRMED: 1,
  PENDING: 2,
  CANCELED: 3,
}

export const PRE_STUDY = {
  INSIGHT: 'insight',
  YOUTUBE: 'youtube',
  ARTICLE: 'article',
  MP3: 'mp3',
  EXPRESSION: 'expression',
  QUESTION: 'question',
  UPLOAD: 'upload',
  UPLOAD_FILE: 'upload_file',
  STYLE: 'style',
}

export const STUDY_REVIEW = {
  MP3: 'mp3',
  SCRIPT: 'script',
  FEEDBACK: 'feedback',
  HOMEWORK: 'homework',
  CAF: 'caf',
}

export const USER_INFO_STATUS = {
  START: 1,
  COMPLETED: 2,
}

export const MAX_RATING = 5

// Sentry
export const NEXT_PUBLIC_SENTRY_DSN = 'https://a7892dfb950543238d98676675725e47@o199905.ingest.sentry.io/6093896'

// Free topic 교재
export const SPEAKING_FOCUSED_ID = 28
export const WRITING_FOCUSED_ID = 27
export const SPEAKING_FOCUSED_FOR_INTERVIEW = 1481

export const FACEBOOK_APP_ID = '1070991856251904'
export const GOOGLE_CLIENT_ID = '398556539785-0a99kq821p2kdop2h5pkvbpho9tqnli2.apps.googleusercontent.com'

// APP link
export const RINGLE_DYNAMIC_LINK = 'https://goo.gl/QfALoK'
export const RINGLE_DYNAMIC_MATERIAL_LINK = 'https://ringle.page.link/4tX7'

export const RINGLE_DYNAMIC_WEBINAR_LINK = 'https://ringle.page.link/ZbN3'
export const RINGLE_DYNAMIC_NOTIFICATION_SETTING_LINK = 'https://ringle.page.link/jq2a'
export const RINGLE_DYNAMIC_CHALLENGE = 'https://ringle.page.link/6Jsx'
export const ANDROID_LINK = 'https://play.google.com/store/apps/details?id=com.ringle'
export const IOS_LINK = 'https://appsto.re/kr/mnb7gb.i'

export const TUTOR_ANDROID_LINK = 'https://play.google.com/store/apps/details?id=com.ringletutor'
export const TUTOR_IOS_LINK = 'https://apps.apple.com/kr/app/ringle-tutor/id1207702138'

// Toss

export const TOSS_CLIENT_KEY = 'live_ck_YyZqmkKeP8goBML19q48bQRxB9lG'

export const BRAZE_STUDENT_WEB_KEY_PROD = '0632cf3b-e8df-482c-9961-b7c9df502085'
export const BRAZE_STUDENT_WEB_KEY_QA = '542d675b-a18e-4a09-9727-d3fde42270a1'

// Track
export const LOG_CATEGORY = {
  READ: 0,
  WRITE: 1,
  LISTEN: 2,
  SPEAK: 3,
  VIEW: 4, //삭제 예정
}
export const LOG_TYPE = {
  COURSE: {
    READ: 0, //o
    MP3_LISTEN: 1, //o
    VIDEO: 2, //o
    ARTICLE: 3, //o
    EXPRESSION_STUDY: 4, //o
    EXPRESSION_LISTEN: 5, //o
    ANSWER_WRITE: 6, //o
    ANSWER_READ: 7, //링킹 X
    REQUESTED_TEXT_WRITE: 17,
  },
  LESSON: {
    NOTE_WRITE: 8, //링킹 X
    NOTE_READ: 9, //링킹 X
    LISTEN: 10, //o
    FEEDBACK_READ: 11, //o
    ENTER: 12, //o
  },
  WEBINAR: {
    VIEW: 13, //o
    LIVE_VIEW: 14, //o
  },
  CHALLENGE: {
    MEMO: 15, //링킹 X
    QUIZ: 16, //링킹 X
  },
  CURATION: {
    COURSE_READ: 18,
    WEBINAR_READ: 19,
  },
}
export const LOCATION = {
  PREP: 0,
  REVIEW: 1,
  COURSE: 2,
  LESSON: 3,
  CURATION: 7,
  HOMEWORK: 8,
}

export const PROMOTION_DUE_DATE = Date.UTC(2024, 1, 29, 15)
export const KR_TIME_DIFF = 9 * 60 * 60 * 1000
export const MY_ORIGINAL = {
  WEBINAR: 1,
  COURSE: 2,
  MY_TUTOR: 3,
  EXP_TUTOR: 4,
  EXP_COURSE: 5,
}

export const ASIA_SEOUL = 'Asia/Seoul'

export const TUTOR_TYPE = {
  RECOMMEND_TUTOR: 'recommendTutors',
  NEW_TUTOR: 'newTutors',
  ALL_TUTOR: 'allTutors',
  MY_TUTOR: 'myTutors',
  NEW_UNOCCUPIED_TUTOR: 'newUnoccupiedTutors',
  OLD_UNOCCUPIED_TUTOR: 'oldUnoccupiedTutors',
  EXPERIENCE_TUTOR: 'experienceTutors',
  SEARCH_TUTOR_RESULT: 'searchedTutors',
  ROOKIE_TUTOR: 'rookieTutors',
}

export const RTRA_PROGRAM_TYPE = {
  READ_TOGETHER: 'readTogether',
  READ_ALONE: 'readAlone',
}

// COLORS
export const COLORS = {
  BLACK: colors.black,
  DIM: colors.dim,
  WHITE: colors.white,

  GRAY50: colors.gray[50],
  GRAY100: colors.gray[100],
  GRAY200: colors.gray[200],
  GRAY300: colors.gray[300],
  GRAY400: colors.gray[400],
  GRAY500: colors.gray[500],
  GRAY600: colors.gray[600],
  GRAY700: colors.gray[700],
  GRAY800: colors.gray[800],
  GRAY900: colors.gray[900],

  PURPLE: colors.purple.DEFAULT,
  PURPLE50: colors.purple[50],
  PURPLE100: colors.purple[100],
  PURPLE200: colors.purple[200],
  PURPLE300: colors.purple[300],
  PURPLE400: colors.purple[400],
  PURPLE500: colors.purple[500],
  PURPLE600: colors.purple[600],
  PURPLE700: colors.purple[700],
  PURPLE800: colors.purple[800],
  PURPLE900: colors.purple[900],

  BLUE: colors.blue.DEFAULT,
  BLUE50: colors.blue[50],
  BLUE100: colors.blue[100],
  BLUE200: colors.blue[200],
  BLUE300: colors.blue[300],
  BLUE400: colors.blue[400],
  BLUE500: colors.blue[500],
  BLUE600: colors.blue[600],
  BLUE700: colors.blue[700],
  BLUE800: colors.blue[800],
  BLUE900: colors.blue[900],

  GREEN: colors.green.DEFAULT,
  GREEN50: colors.green[50],
  GREEN100: colors.green[100],
  GREEN200: colors.green[200],
  GREEN300: colors.green[300],
  GREEN400: colors.green[400],
  GREEN500: colors.green[500],
  GREEN600: colors.green[600],
  GREEN700: colors.green[700],
  GREEN800: colors.green[800],
  GREEN900: colors.green[900],

  RED: colors.red.DEFAULT,
  RED50: colors.red[50],
  RED100: colors.red[100],
  RED200: colors.red[200],
  RED300: colors.red[300],
  RED400: colors.red[400],
  RED500: colors.red[500],
  RED600: colors.red[600],
  RED700: colors.red[700],
  RED800: colors.red[800],
  RED900: colors.red[900],

  YELLOW: colors.yellow.DEFAULT,
  YELLOW50: colors.yellow[50],
  YELLOW100: colors.yellow[100],
  YELLOW200: colors.yellow[200],
  YELLOW300: colors.yellow[300],
  YELLOW400: colors.yellow[400],
  YELLOW500: colors.yellow[500],
  YELLOW600: colors.yellow[600],
  YELLOW700: colors.yellow[700],
  YELLOW800: colors.yellow[800],
  YELLOW900: colors.yellow[900],
}

export type COLOR_KEY = (typeof COLORS)[keyof typeof COLORS]

export const COUNTRY_CODE = [
  { name: 'South Korea', code: '+82' },
  { name: 'United States', code: '+1' },
  { name: 'Japan', code: '+81' },
  { name: 'Singapore', code: '+65' },
  { name: 'Canada', code: '+1' },
  { name: 'United Kingdom', code: '+44' },
  { name: 'Australia', code: '+61' },
  { name: 'Germany', code: '+49' },
  { name: 'Vietnam', code: '+84' },
  { name: 'Hong Kong', code: '+852' },
  { name: 'Netherlands', code: '+31' },
  { name: 'Afghanistan', code: '+93' },
  { name: 'Aland Islands', code: '+358' },
  { name: 'Albania', code: '+355' },
  { name: 'Algeria', code: '+213' },
  { name: 'AmericanSamoa', code: '+1684' },
  { name: 'Andorra', code: '+376' },
  { name: 'Angola', code: '+244' },
  { name: 'Anguilla', code: '+1264' },
  { name: 'Antarctica', code: '+672' },
  { name: 'Antigua and Barbuda', code: '+1268' },
  { name: 'Argentina', code: '+54' },
  { name: 'Armenia', code: '+374' },
  { name: 'Aruba', code: '+297' },
  // { name: 'Australia', code: '+61' },
  { name: 'Austria', code: '+43' },
  { name: 'Azerbaijan', code: '+994' },
  { name: 'Bahamas', code: '+1242' },
  { name: 'Bahrain', code: '+973' },
  { name: 'Bangladesh', code: '+880' },
  { name: 'Barbados', code: '+1246' },
  { name: 'Belarus', code: '+375' },
  { name: 'Belgium', code: '+32' },
  { name: 'Belize', code: '+501' },
  { name: 'Benin', code: '+229' },
  { name: 'Bermuda', code: '+1441' },
  { name: 'Bhutan', code: '+975' },
  { name: 'Bolivia, Plurinational State of', code: '+591' },
  { name: 'Bosnia and Herzegovina', code: '+387' },
  { name: 'Botswana', code: '+267' },
  { name: 'Brazil', code: '+55' },
  { name: 'British Indian Ocean Territory', code: '+246' },
  { name: 'Brunei Darussalam', code: '+673' },
  { name: 'Bulgaria', code: '+359' },
  { name: 'Burkina Faso', code: '+226' },
  { name: 'Burundi', code: '+257' },
  { name: 'Cambodia', code: '+855' },
  { name: 'Cameroon', code: '+237' },
  // { name: 'Canada', code: '+1' },
  { name: 'Cape Verde', code: '+238' },
  { name: 'Cayman Islands', code: '+ 345' },
  { name: 'Central African Republic', code: '+236' },
  { name: 'Chad', code: '+235' },
  { name: 'Chile', code: '+56' },
  { name: 'China', code: '+86' },
  { name: 'Christmas Island', code: '+61' },
  { name: 'Cocos (Keeling) Islands', code: '+61' },
  { name: 'Colombia', code: '+57' },
  { name: 'Comoros', code: '+269' },
  { name: 'Congo', code: '+242' },
  { name: 'Congo, The Democratic Republic of the Congo', code: '+243' },
  { name: 'Cook Islands', code: '+682' },
  { name: 'Costa Rica', code: '+506' },
  { name: "Cote d'Ivoire", code: '+225' },
  { name: 'Croatia', code: '+385' },
  { name: 'Cuba', code: '+53' },
  { name: 'Cyprus', code: '+357' },
  { name: 'Czech Republic', code: '+420' },
  { name: 'Denmark', code: '+45' },
  { name: 'Djibouti', code: '+253' },
  { name: 'Dominica', code: '+1767' },
  { name: 'Dominican Republic', code: '+1849' },
  { name: 'Ecuador', code: '+593' },
  { name: 'Egypt', code: '+20' },
  { name: 'El Salvador', code: '+503' },
  { name: 'Equatorial Guinea', code: '+240' },
  { name: 'Eritrea', code: '+291' },
  { name: 'Estonia', code: '+372' },
  { name: 'Ethiopia', code: '+251' },
  { name: 'Falkland Islands (Malvinas)', code: '+500' },
  { name: 'Faroe Islands', code: '+298' },
  { name: 'Fiji', code: '+679' },
  { name: 'Finland', code: '+358' },
  { name: 'France', code: '+33' },
  { name: 'French Guiana', code: '+594' },
  { name: 'French Polynesia', code: '+689' },
  { name: 'Gabon', code: '+241' },
  { name: 'Gambia', code: '+220' },
  { name: 'Georgia', code: '+995' },
  // { name: 'Germany', code: '+49' },
  { name: 'Ghana', code: '+233' },
  { name: 'Gibraltar', code: '+350' },
  { name: 'Greece', code: '+30' },
  { name: 'Greenland', code: '+299' },
  { name: 'Grenada', code: '+1473' },
  { name: 'Guadeloupe', code: '+590' },
  { name: 'Guam', code: '+1671' },
  { name: 'Guatemala', code: '+502' },
  { name: 'Guernsey', code: '+44' },
  { name: 'Guinea', code: '+224' },
  { name: 'Guinea-Bissau', code: '+245' },
  { name: 'Guyana', code: '+595' },
  { name: 'Haiti', code: '+509' },
  { name: 'Holy See (Vatican City State)', code: '+379' },
  { name: 'Honduras', code: '+504' },
  // { name: 'Hong Kong', code: '+852' },
  { name: 'Hungary', code: '+36' },
  { name: 'Iceland', code: '+354' },
  { name: 'India', code: '+91' },
  { name: 'Indonesia', code: '+62' },
  { name: 'Iran, Islamic Republic of Persian Gulf', code: '+98' },
  { name: 'Iraq', code: '+964' },
  { name: 'Ireland', code: '+353' },
  { name: 'Isle of Man', code: '+44' },
  { name: 'Israel', code: '+972' },
  { name: 'Italy', code: '+39' },
  { name: 'Jamaica', code: '+1876' },
  // { name: 'Japan', code: '+81' },
  { name: 'Jersey', code: '+44' },
  { name: 'Jordan', code: '+962' },
  { name: 'Kazakhstan', code: '+77' },
  { name: 'Kenya', code: '+254' },
  { name: 'Kiribati', code: '+686' },
  {
    name: "Korea, Democratic People's Republic of Korea",
    code: '+850',
  },
  //{ name: 'Korea, Republic of South Korea', code: '+82' },
  { name: 'Kuwait', code: '+965' },
  { name: 'Kyrgyzstan', code: '+996' },
  { name: 'Laos', code: '+856' },
  { name: 'Latvia', code: '+371' },
  { name: 'Lebanon', code: '+961' },
  { name: 'Lesotho', code: '+266' },
  { name: 'Liberia', code: '+231' },
  { name: 'Libyan Arab Jamahiriya', code: '+218' },
  { name: 'Liechtenstein', code: '+423' },
  { name: 'Lithuania', code: '+370' },
  { name: 'Luxembourg', code: '+352' },
  { name: 'Macao', code: '+853' },
  { name: 'Macedonia', code: '+389' },
  { name: 'Madagascar', code: '+261' },
  { name: 'Malawi', code: '+265' },
  { name: 'Malaysia', code: '+60' },
  { name: 'Maldives', code: '+960' },
  { name: 'Mali', code: '+223' },
  { name: 'Malta', code: '+356' },
  { name: 'Marshall Islands', code: '+692' },
  { name: 'Martinique', code: '+596' },
  { name: 'Mauritania', code: '+222' },
  { name: 'Mauritius', code: '+230' },
  { name: 'Mayotte', code: '+262' },
  { name: 'Mexico', code: '+52' },
  {
    name: 'Micronesia, Federated States of Micronesia',
    code: '+691',
  },
  { name: 'Moldova', code: '+373' },
  { name: 'Monaco', code: '+377' },
  { name: 'Mongolia', code: '+976' },
  { name: 'Montenegro', code: '+382' },
  { name: 'Montserrat', code: '+1664' },
  { name: 'Morocco', code: '+212' },
  { name: 'Mozambique', code: '+258' },
  { name: 'Myanmar', code: '+95' },
  { name: 'Namibia', code: '+264' },
  { name: 'Nauru', code: '+674' },
  { name: 'Nepal', code: '+977' },
  // { name: 'Netherlands', code: '+31' },
  { name: 'Netherlands Antilles', code: '+599' },
  { name: 'New Caledonia', code: '+687' },
  { name: 'New Zealand', code: '+64' },
  { name: 'Nicaragua', code: '+505' },
  { name: 'Niger', code: '+227' },
  { name: 'Nigeria', code: '+234' },
  { name: 'Niue', code: '+683' },
  { name: 'Norfolk Island', code: '+672' },
  { name: 'Northern Mariana Islands', code: '+1670' },
  { name: 'Norway', code: '+47' },
  { name: 'Oman', code: '+968' },
  { name: 'Pakistan', code: '+92' },
  { name: 'Palau', code: '+680' },
  { name: 'Palestinian Territory, Occupied', code: '+970' },
  { name: 'Panama', code: '+507' },
  { name: 'Papua New Guinea', code: '+675' },
  { name: 'Paraguay', code: '+595' },
  { name: 'Peru', code: '+51' },
  { name: 'Philippines', code: '+63' },
  { name: 'Pitcairn', code: '+872' },
  { name: 'Poland', code: '+48' },
  { name: 'Portugal', code: '+351' },
  { name: 'Puerto Rico', code: '+1939' },
  { name: 'Qatar', code: '+974' },
  { name: 'Romania', code: '+40' },
  { name: 'Russia', code: '+7' },
  { name: 'Rwanda', code: '+250' },
  { name: 'Reunion', code: '+262' },
  { name: 'Saint Barthelemy', code: '+590' },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    code: '+290',
  },
  { name: 'Saint Kitts and Nevis', code: '+1869' },
  { name: 'Saint Lucia', code: '+1758' },
  { name: 'Saint Martin', code: '+590' },
  { name: 'Saint Pierre and Miquelon', code: '+508' },
  { name: 'Saint Vincent and the Grenadines', code: '+1784' },
  { name: 'Samoa', code: '+685' },
  { name: 'San Marino', code: '+378' },
  { name: 'Sao Tome and Principe', code: '+239' },
  { name: 'Saudi Arabia', code: '+966' },
  { name: 'Senegal', code: '+221' },
  { name: 'Serbia', code: '+381' },
  { name: 'Seychelles', code: '+248' },
  { name: 'Sierra Leone', code: '+232' },
  // { name: 'Singapore', code: '+65' },
  { name: 'Slovakia', code: '+421' },
  { name: 'Slovenia', code: '+386' },
  { name: 'Solomon Islands', code: '+677' },
  { name: 'Somalia', code: '+252' },
  { name: 'South Africa', code: '+27' },
  { name: 'South Sudan', code: '+211' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: '+500',
  },
  { name: 'Spain', code: '+34' },
  { name: 'Sri Lanka', code: '+94' },
  { name: 'Sudan', code: '+249' },
  { name: 'Suriname', code: '+597' },
  { name: 'Svalbard and Jan Mayen', code: '+47' },
  { name: 'Swaziland', code: '+268' },
  { name: 'Sweden', code: '+46' },
  { name: 'Switzerland', code: '+41' },
  { name: 'Syrian Arab Republic', code: '+963' },
  { name: 'Taiwan', code: '+886' },
  { name: 'Tajikistan', code: '+992' },
  { name: 'Tanzania, United Republic of Tanzania', code: '+255' },
  { name: 'Thailand', code: '+66' },
  { name: 'Timor-Leste', code: '+670' },
  { name: 'Togo', code: '+228' },
  { name: 'Tokelau', code: '+690' },
  { name: 'Tonga', code: '+676' },
  { name: 'Trinidad and Tobago', code: '+1868' },
  { name: 'Tunisia', code: '+216' },
  { name: 'Turkey', code: '+90' },
  { name: 'Turkmenistan', code: '+993' },
  { name: 'Turks and Caicos Islands', code: '+1649' },
  { name: 'Tuvalu', code: '+688' },
  { name: 'Uganda', code: '+256' },
  { name: 'Ukraine', code: '+380' },
  { name: 'United Arab Emirates', code: '+971' },
  // { name: 'United Kingdom', code: '+44' },
  //{ name: 'United States', code: '+1' },
  { name: 'Uruguay', code: '+598' },
  { name: 'Uzbekistan', code: '+998' },
  { name: 'Vanuatu', code: '+678' },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    code: '+58',
  },
  // { name: 'Vietnam', code: '+84' },
  { name: 'Virgin Islands, British', code: '+1284' },
  { name: 'Virgin Islands, U.S.', code: '+1340' },
  { name: 'Wallis and Futuna', code: '+681' },
  { name: 'Yemen', code: '+967' },
  { name: 'Zambia', code: '+260' },
  { name: 'Zimbabwe', code: '+263' },
]

export const LANGUAGES = [
  {
    name: 'English',
    nativeName: 'English',
  },
  {
    name: 'Korean',
    nativeName: '한국어',
  },
  {
    name: 'Japanese',
    nativeName: '日本語',
  },
  {
    name: 'Chinese (Simplified)',
    nativeName: '简体中文',
  },
  {
    name: 'Chinese (Traditional)',
    nativeName: '繁體中文',
  },
  {
    name: 'Vietnamese',
    nativeName: 'Tiếng Việt',
  },
  {
    name: 'Hindi',
    nativeName: 'हिन्दी, हिंदी',
  },
  {
    name: 'Malay',
    nativeName: 'bahasa Melayu, بهاس ملايو',
  },
  {
    name: 'Indonesian',
    nativeName: 'Bahasa Indonesia',
  },
  {
    name: 'Thai',
    nativeName: 'ไทย',
  },
  {
    name: 'Arabic',
    nativeName: 'العربية',
  },
  {
    name: 'Abkhaz',
    nativeName: 'аҧсуа',
  },
  {
    name: 'Afar',
    nativeName: 'Afaraf',
  },
  {
    name: 'Afrikaans',
    nativeName: 'Afrikaans',
  },
  {
    name: 'Akan',
    nativeName: 'Akan',
  },
  {
    name: 'Albanian',
    nativeName: 'Shqip',
  },
  {
    name: 'Amharic',
    nativeName: 'አማርኛ',
  },
  {
    name: 'Aragonese',
    nativeName: 'Aragonés',
  },
  {
    name: 'Armenian',
    nativeName: 'Հայերեն',
  },
  {
    name: 'Assamese',
    nativeName: 'অসমীয়া',
  },
  {
    name: 'Avaric',
    nativeName: 'авар мацӀ, магӀарул мацӀ',
  },
  {
    name: 'Avestan',
    nativeName: 'avesta',
  },
  {
    name: 'Aymara',
    nativeName: 'aymar aru',
  },
  {
    name: 'Azerbaijani',
    nativeName: 'azərbaycan dili',
  },
  {
    name: 'Bambara',
    nativeName: 'bamanankan',
  },
  {
    name: 'Bashkir',
    nativeName: 'башҡорт теле',
  },
  {
    name: 'Basque',
    nativeName: 'euskara, euskera',
  },
  {
    name: 'Belarusian',
    nativeName: 'Беларуская',
  },
  {
    name: 'Bengali',
    nativeName: 'বাংলা',
  },
  {
    name: 'Bihari',
    nativeName: 'भोजपुरी',
  },
  {
    name: 'Bislama',
    nativeName: 'Bislama',
  },
  {
    name: 'Bosnian',
    nativeName: 'bosanski jezik',
  },
  {
    name: 'Breton',
    nativeName: 'brezhoneg',
  },
  {
    name: 'Bulgarian',
    nativeName: 'български език',
  },
  {
    name: 'Burmese',
    nativeName: 'ဗမာစာ',
  },
  {
    name: 'Catalan; Valencian',
    nativeName: 'Català',
  },
  {
    name: 'Chamorro',
    nativeName: 'Chamoru',
  },
  {
    name: 'Chechen',
    nativeName: 'нохчийн мотт',
  },
  {
    name: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
  },
  {
    name: 'Chuvash',
    nativeName: 'чӑваш чӗлхи',
  },
  {
    name: 'Cornish',
    nativeName: 'Kernewek',
  },
  {
    name: 'Corsican',
    nativeName: 'corsu, lingua corsa',
  },
  {
    name: 'Cree',
    nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ',
  },
  {
    name: 'Croatian',
    nativeName: 'hrvatski',
  },
  {
    name: 'Czech',
    nativeName: 'česky, čeština',
  },
  {
    name: 'Danish',
    nativeName: 'dansk',
  },
  {
    name: 'Divehi; Dhivehi; Maldivian;',
    nativeName: 'ދިވެހި',
  },
  {
    name: 'Dutch',
    nativeName: 'Nederlands, Vlaams',
  },
  {
    name: 'Esperanto',
    nativeName: 'Esperanto',
  },
  {
    name: 'Estonian',
    nativeName: 'eesti, eesti keel',
  },
  {
    name: 'Ewe',
    nativeName: 'Eʋegbe',
  },
  {
    name: 'Faroese',
    nativeName: 'føroyskt',
  },
  {
    name: 'Fijian',
    nativeName: 'vosa Vakaviti',
  },
  {
    name: 'Finnish',
    nativeName: 'suomi, suomen kieli',
  },
  {
    name: 'French',
    nativeName: 'français, langue française',
  },
  {
    name: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  {
    name: 'Galician',
    nativeName: 'Galego',
  },
  {
    name: 'Georgian',
    nativeName: 'ქართული',
  },
  {
    name: 'German',
    nativeName: 'Deutsch',
  },
  {
    name: 'Greek, Modern',
    nativeName: 'Ελληνικά',
  },
  {
    name: 'Guaraní',
    nativeName: 'Avañeẽ',
  },
  {
    name: 'Gujarati',
    nativeName: 'ગુજરાતી',
  },
  {
    name: 'Haitian; Haitian Creole',
    nativeName: 'Kreyòl ayisyen',
  },
  {
    name: 'Hausa',
    nativeName: 'Hausa, هَوُسَ',
  },
  {
    name: 'Hebrew (modern)',
    nativeName: 'עברית',
  },
  {
    name: 'Herero',
    nativeName: 'Otjiherero',
  },
  {
    name: 'Hiri Motu',
    nativeName: 'Hiri Motu',
  },
  {
    name: 'Hungarian',
    nativeName: 'Magyar',
  },
  {
    name: 'Interlingua',
    nativeName: 'Interlingua',
  },
  {
    name: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
  },
  {
    name: 'Irish',
    nativeName: 'Gaeilge',
  },
  {
    name: 'Igbo',
    nativeName: 'Asụsụ Igbo',
  },
  {
    name: 'Inupiaq',
    nativeName: 'Iñupiaq, Iñupiatun',
  },
  {
    name: 'Ido',
    nativeName: 'Ido',
  },
  {
    name: 'Icelandic',
    nativeName: 'Íslenska',
  },
  {
    name: 'Italian',
    nativeName: 'Italiano',
  },
  {
    name: 'Inuktitut',
    nativeName: 'ᐃᓄᒃᑎᑐᑦ',
  },
  {
    name: 'Javanese',
    nativeName: 'basa Jawa',
  },
  {
    name: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  {
    name: 'Kannada',
    nativeName: 'ಕನ್ನಡ',
  },
  {
    name: 'Kanuri',
    nativeName: 'Kanuri',
  },
  {
    name: 'Kashmiri',
    nativeName: 'कश्मीरी, كشميري‎',
  },
  {
    name: 'Kazakh',
    nativeName: 'Қазақ тілі',
  },
  {
    name: 'Khmer',
    nativeName: 'ភាសាខ្មែរ',
  },
  {
    name: 'Kikuyu, Gikuyu',
    nativeName: 'Gĩkũyũ',
  },
  {
    name: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda',
  },
  {
    name: 'Kirghiz, Kyrgyz',
    nativeName: 'кыргыз тили',
  },
  {
    name: 'Komi',
    nativeName: 'коми кыв',
  },
  {
    name: 'Kongo',
    nativeName: 'KiKongo',
  },
  {
    name: 'Kurdish',
    nativeName: 'Kurdî, كوردی‎',
  },
  {
    name: 'Kwanyama, Kuanyama',
    nativeName: 'Kuanyama',
  },
  {
    name: 'Latin',
    nativeName: 'latine, lingua latina',
  },
  {
    name: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
  },
  {
    name: 'Luganda',
    nativeName: 'Luganda',
  },
  {
    name: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
  },
  {
    name: 'Lingala',
    nativeName: 'Lingála',
  },
  {
    name: 'Lao',
    nativeName: 'ພາສາລາວ',
  },
  {
    name: 'Lithuanian',
    nativeName: 'lietuvių kalba',
  },
  {
    name: 'Luba-Katanga',
    nativeName: '',
  },
  {
    name: 'Latvian',
    nativeName: 'latviešu valoda',
  },
  {
    name: 'Manx',
    nativeName: 'Gaelg, Gailck',
  },
  {
    name: 'Macedonian',
    nativeName: 'македонски јазик',
  },
  {
    name: 'Malagasy',
    nativeName: 'Malagasy fiteny',
  },
  {
    name: 'Malayalam',
    nativeName: 'മലയാളം',
  },
  {
    name: 'Maltese',
    nativeName: 'Malti',
  },
  {
    name: 'Māori',
    nativeName: 'te reo Māori',
  },
  {
    name: 'Marathi (Marāṭhī)',
    nativeName: 'मराठी',
  },
  {
    name: 'Marshallese',
    nativeName: 'Kajin M̧ajeļ',
  },
  {
    name: 'Mongolian',
    nativeName: 'монгол',
  },
  {
    name: 'Nauru',
    nativeName: 'Ekakairũ Naoero',
  },
  {
    name: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí',
  },
  {
    name: 'Norwegian Bokmål',
    nativeName: 'Norsk bokmål',
  },
  {
    name: 'North Ndebele',
    nativeName: 'isiNdebele',
  },
  {
    name: 'Nepali',
    nativeName: 'नेपाली',
  },
  {
    name: 'Ndonga',
    nativeName: 'Owambo',
  },
  {
    name: 'Norwegian Nynorsk',
    nativeName: 'Norsk nynorsk',
  },
  {
    name: 'Norwegian',
    nativeName: 'Norsk',
  },
  {
    name: 'Nuosu',
    nativeName: 'ꆈꌠ꒿ Nuosuhxop',
  },
  {
    name: 'South Ndebele',
    nativeName: 'isiNdebele',
  },
  {
    name: 'Occitan',
    nativeName: 'Occitan',
  },
  {
    name: 'Ojibwe, Ojibwa',
    nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
  },
  {
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  {
    name: 'Oromo',
    nativeName: 'Afaan Oromoo',
  },
  {
    name: 'Oriya',
    nativeName: 'ଓଡ଼ିଆ',
  },
  {
    name: 'Ossetian, Ossetic',
    nativeName: 'ирон æвзаг',
  },
  {
    name: 'Panjabi, Punjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
  },
  {
    name: 'Pāli',
    nativeName: 'पाऴि',
  },
  {
    name: 'Persian',
    nativeName: 'فارسی',
  },
  {
    name: 'Polish',
    nativeName: 'polski',
  },
  {
    name: 'Pashto, Pushto',
    nativeName: 'پښتو',
  },
  {
    name: 'Portuguese',
    nativeName: 'Português',
  },
  {
    name: 'Quechua',
    nativeName: 'Runa Simi, Kichwa',
  },
  {
    name: 'Romansh',
    nativeName: 'rumantsch grischun',
  },
  {
    name: 'Kirundi',
    nativeName: 'kiRundi',
  },
  {
    name: 'Romanian, Moldavian, Moldovan',
    nativeName: 'română',
  },
  {
    name: 'Russian',
    nativeName: 'русский язык',
  },
  {
    name: 'Sanskrit (Saṁskṛta)',
    nativeName: 'संस्कृतम्',
  },
  {
    name: 'Sardinian',
    nativeName: 'sardu',
  },
  {
    name: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎',
  },
  {
    name: 'Northern Sami',
    nativeName: 'Davvisámegiella',
  },
  {
    name: 'Samoan',
    nativeName: 'gagana faa Samoa',
  },
  {
    name: 'Sango',
    nativeName: 'yângâ tî sängö',
  },
  {
    name: 'Serbian',
    nativeName: 'српски језик',
  },
  {
    name: 'Scottish Gaelic; Gaelic',
    nativeName: 'Gàidhlig',
  },
  {
    name: 'Shona',
    nativeName: 'chiShona',
  },
  {
    name: 'Sinhala, Sinhalese',
    nativeName: 'සිංහල',
  },
  {
    name: 'Slovak',
    nativeName: 'slovenčina',
  },
  {
    name: 'Slovene',
    nativeName: 'slovenščina',
  },
  {
    name: 'Somali',
    nativeName: 'Soomaaliga, af Soomaali',
  },
  {
    name: 'Southern Sotho',
    nativeName: 'Sesotho',
  },
  {
    name: 'Spanish; Castilian',
    nativeName: 'español, castellano',
  },
  {
    name: 'Sundanese',
    nativeName: 'Basa Sunda',
  },
  {
    name: 'Swahili',
    nativeName: 'Kiswahili',
  },
  {
    name: 'Swati',
    nativeName: 'SiSwati',
  },
  {
    name: 'Swedish',
    nativeName: 'svenska',
  },
  {
    name: 'Tamil',
    nativeName: 'தமிழ்',
  },
  {
    name: 'Telugu',
    nativeName: 'తెలుగు',
  },
  {
    name: 'Tajik',
    nativeName: 'тоҷикӣ, toğikī, تاجیکی‎',
  },
  {
    name: 'Tigrinya',
    nativeName: 'ትግርኛ',
  },
  {
    name: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
  },
  {
    name: 'Turkmen',
    nativeName: 'Türkmen, Түркмен',
  },
  {
    name: 'Tagalog',
    nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
  },
  {
    name: 'Tswana',
    nativeName: 'Setswana',
  },
  {
    name: 'Tonga (Tonga Islands)',
    nativeName: 'faka Tonga',
  },
  {
    name: 'Turkish',
    nativeName: 'Türkçe',
  },
  {
    name: 'Tsonga',
    nativeName: 'Xitsonga',
  },
  {
    name: 'Tatar',
    nativeName: 'татарча, tatarça, تاتارچا‎',
  },
  {
    name: 'Twi',
    nativeName: 'Twi',
  },
  {
    name: 'Tahitian',
    nativeName: 'Reo Tahiti',
  },
  {
    name: 'Uighur, Uyghur',
    nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
  },
  {
    name: 'Ukrainian',
    nativeName: 'українська',
  },
  {
    name: 'Urdu',
    nativeName: 'اردو',
  },
  {
    name: 'Uzbek',
    nativeName: 'zbek, Ўзбек, أۇزبېك‎',
  },
  {
    name: 'Venda',
    nativeName: 'Tshivenḓa',
  },
  {
    name: 'Volapük',
    nativeName: 'Volapük',
  },
  {
    name: 'Walloon',
    nativeName: 'Walon',
  },
  {
    name: 'Welsh',
    nativeName: 'Cymraeg',
  },
  {
    name: 'Wolof',
    nativeName: 'Wollof',
  },
  {
    name: 'Western Frisian',
    nativeName: 'Frysk',
  },
  {
    name: 'Xhosa',
    nativeName: 'isiXhosa',
  },
  {
    name: 'Yiddish',
    nativeName: 'ייִדיש',
  },
  {
    name: 'Yoruba',
    nativeName: 'Yorùbá',
  },
  {
    name: 'Zhuang, Chuang',
    nativeName: 'Saɯ cueŋƅ, Saw cuengh',
  },
]

export const TODO_TYPES = {
  HOMEWORK: 'Homework',
  PREP: 'Prep',
  REVIEW: 'Review',
}

export const COURSE_TYPES = {
  UPLOAD_FILE: 'upload_file',
  UPLOAD_YOUTUBE: 'upload_youtube',
  UPLOAD_ARTICLE: 'upload_article',
  DISCUSSION: 'discussion',
  LWT: 'LWT', // Let's write together course
}

export const TUTOR_SLOT_TYPES = {
  GOOGLE: 'google',
  SCHEDULED_PLUS: 'scheduled_plus', // requested || confirmed
  SCHEDULED_TEENS: 'scheduled_teens', // requested || confirmed
  OPENED: 'opened',
  UNASSIGNED: 'unassigned',
  REQUESTED: 'requested', //student requested && not confirmed yet
}

export const STUDENT_TYPE = {
  PLUS: 'plus',
  TEENS: 'teens',
}

export const TUTOR_SCHEDULE_ACCEPT_STATUS = {
  NEEDS_ACTION: 'needsAction',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  STUDENT_CANCELED: 'studentCanceled',
}

export const GOOGLE_ACCEPT_STATUS = {
  TENTATIVE: 'tentative',
  ACCEPTED: 'accepted',
}

export const PAST_LESSON_FILTER = {
  FEEDBACK_REQUIRED: 'feedback_required',
  ALL: 'all',
}

export const JUNIOR_PREFERENCE = {
  LIKE: 0,
  NO_PREFERENCE: 1,
  DISLIKE: 2,
}

export const LOWER_HASHTAG_ID = {
  FOR_TEENS: 82,
}
export const HOMEWORK_DEFAULT_QUESTION = 'Enter another topic for the student'

export const TUTOR_NOTIFICATION_IMPORTANT_BADGE_LIST = [
  { title: 'Lesson Request', type: 'important', id: 1 },
  { title: 'Lesson Reminder', type: 'important', id: 2 },
  { title: 'Feedback Report', type: 'important', id: 3 },
  { title: 'Homework ', type: 'important', id: 4 },
  { title: 'Request Reminder', type: 'important', id: 5 },
]

// 현재 프로모션 기간 여부
export const IS_NOW_PROMO = true
export const SURVEY_SPARROW_OT_REGISTER = 'https://whattime.co.kr/ringle/orientation-new'

// Zoom Help zoom_tooltip 최초 1회 노출 여부
export const ZOOM_HELP_TOOLTIP = 'zoom_help_tooltip'

// login 요청 시 request의 username 을 구성하는 prefix
export const SOCIAL_LOGIN_PREFIX = 'snslogin:'
export const EMAIL_LOGIN_PREFIX = 'email:'

export const PROMOTION_WEBINAR_IDS = [
  688, 689, 690, 691, 692, 693, 694, 695, 696, 697, 698, 699, 700, 701, 702, 703, 704, 705,
]

// CONVERSATION AI PROMPT

export const CONVERSATION_ROLE = {
  ASSISTANT: 'assistant',
  USER: 'user',
  SYSTEM: 'system',
}

export const COURSE_PROMPT = (course) => `
  <course_material>
  ${course}
  </course_material>
`

export const INSTRUCTION_PROMPT = `
    <objective>
    Your objective is to help students practice speaking English in a fun way, while following specific guidelines about a given topic.
    </objective>

    <persona>
    You are an English tutor, who teaches non-native speakers how to converse in English.
    </persona>

    <tone>
    You should respond in a friendly, casual manner.
    </tone>

    <instructions>
    You will be given course materials, and specific teaching guidelines for how to teach.
    You will also be given the chat history between the student and teacher.
    Your task's first priority is to follow the system's teaching guidelines.
    Your task's second priority is to interact with the student as an English tutor.
    Please make sure you read and understand these instructions carefully.
    Please keep this document open while thinking, and refer to is as needed.
    </instructions>

    <constraints>
    Dos and Don'ts for the following aspects
    1. Dos : Be kind. Be friendly. Make your response engaging and concise, 3 sentences or less.
    2. Don'ts : Don't ask the same question repeatedly to the student. Don't ask the student what to do next.
    </constraints>

    <thinking_steps>
    1. Read the course materials and the previous conversation between the user and assistant carefully.
    2. Read the teaching guidelines for the current situation, and think of a response that follows the instructions.
    3. Double-check that your response follows the constraints, and generate your final response.
    </thinking_steps>

    <few_shot_example_for_beginning>
    - (Tutor): Hello! How are you today?
    - (Student): Hello, I am fine, and you?
    - (Tutor): I'm great! Today, we are going to talk about {topic}. Are you excited to talk about this?
    - (Student): Sure I like talking about {topic}.
    - (Tutor): Before we start, do you have any goals for this session? Such as 1. {goal1}, 2. {goal2}, 3. {goal3}..
    - (Student): I don't have any goals.
    - (Tutor): That's totally fine! Let's begin with a simple question. {question}
    - (Student): {answer}
    - (Tutor): That's really interesting! How about ...
    </few_shot_example_for_beginning>

    <recap>
    Follow the teaching guidelines and the thinking steps when generating your response.
    </recap>
  `

export const THREAD_STATUS = {
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'complete',
  EXPIRED: 'expired',
  NOT_EVALUATED: 'not_evaluated',
  EVALUATED: 'evaluated',
}

export const THREAD_ANALYSIS_STATUS = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  SUCCEED: 'succeed',
  FAILED: 'failed',
}
// EXPIRED
// BEFORE_EVALUATION

export const RECORDING_STATUS = {
  BEFORE_LISTENING: 0,
  END_LISTENING: 1, //
  END_RECORDING: 2, //
  SKIP_RECORDING: 3, //
  START_RECORDING: 4,
}

export const speedsArray = [0.8, 1.0, 1.2, 1.5]
export const difficultyArray = ['Basic', 'Intermediate', 'Advanced']

export const audioKey = (threadId, role, index) => {
  if (IS_PRODUCTION) {
    return `production/${threadId}/${role}-${index}.wav`
  } else {
    return `qa/${threadId}/${role}-${index}.wav`
  }
}

export const audioKeyUrl = (threadId, role, index) => {
  if (role === CONVERSATION_ROLE.SYSTEM) return
  if (IS_PRODUCTION) {
    return `https://conversation-audio-tts.s3.ap-northeast-2.amazonaws.com/production/${threadId}/${role}-${index}.wav`
  } else {
    return `https://conversation-audio-tts.s3.ap-northeast-2.amazonaws.com/qa/${threadId}/${role}-${index}.wav`
  }
}

export const userIndex = (current_dialogs) => {
  return current_dialogs.filter((obj) => obj.role === CONVERSATION_ROLE.USER).length || 0
}

export const getMessagesIndexByRole = (messages, role) => {
  return messages.filter((obj) => obj.role === role).length || 0
}

export const CONVERSATION_TURN_LIMIT = 20
export const CONVERSATION_AI_LIMIT = 10
export const SHOW_CONVERSATION_DIFFICULTY_NUDGE = 3

export const DIFFICULTY_OPTIONS = [
  { id: 0, label: 'Basic' },
  { id: 1, label: 'Intermediate' },
  { id: 2, label: 'Advanced' },
]

export const ROLEPLAY_LISTS = [
  {
    title: '신발매장 (고객/판매원)',
    id: 1,
    key: 'SHOES_ROLEPLAY',
  },
  {
    title: '항만작업 (작업반장/크레인)',
    id: 2,
    key: '',
  },
  {
    title: '촬영 준비 (감독/조명팀)',
    id: 3,
    key: '',
  },
]

export const SHOES_ROLEPLAY = `
<persona>
    You(AI) and user will be doing role-play in shoe store.
    You(AI) will be the customer of shoe store who are trying to buy new shoes.
    User will be the salesperson who is trying to answer the question from customer and provide some recommendations.
</persona>
<situation>
  - You(AI/Customer) was looking for new shoes.
  - You(AI/Customer) are asking some recommendations.
  - You(AI/Customer) are willing to buy a new shoes.
  - You(AI/Customer) can complain about the customer service.
  - User(Salesperson) have to take care of the customer.
  - User(Salesperson) should know well about the customer service policy.
  - User(Salesperson) should lead to sales success.
</situation>
<examples>
  - Hi, do you have these shoes?
  - Can you recommend similar shoes?
</examples>
<guide>
  - As user is non-native english speaker, use easy and short english. (As if you visited a new country)
  - Role-play should be conducted in English.
  - Role-play should be done in 10 turns.
  - When the role-play is done, respond in JSON format {{finished: true}}
  - Then start as if you are a customer!
</guide>
`
